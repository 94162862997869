import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Canvas, useFrame } from "@react-three/fiber";
import Model from "./Dj.js"
import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { asciiFrame } from  './Loading'
import { MainLogo, RunningLogo } from  './AniLogos'
import PlayerComponent from './Player.tsx'
import './index.css'



const links = {
  dj: '',
  content:''
}

// Hides elements in div when the element is not actively clicked
const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}


// Heading component of the website
const HeadingBlock = () => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);

  return (
    <div>
      <ul className="d">
        <MainLogo></MainLogo>
        <div ref={ref} onClick={() => setIsComponentVisible(true)}><RunningLogo></RunningLogo>{isComponentVisible && <DialogBox />}</div> 
        <li><a href={links.dj}>dj</a></li>
        
      </ul>
    </div>

  )
}

//DialogBox that appears when the running man is clicked
const DialogBox = () => {

  let colors  = {
    "Light": ['#fff', '#000', '#fff', '#C7D6F1'],
    "Dark": ['#000', '#fff', '#000', '#C7D6F1'],
    "Blue": ['#8CCAE1', '#FCFFF2', '#9B9AA1', '#1A0C84'],
    "Green": ['#A1DFB4', '#1C6C34', '#fff', '#f85823']
  };
  let variables = ['--primary', '--secondary', '--detail', '--detail2'];


  const handleClick = (event) => {
    const target = event.target.textContent;

    for (let i=0; i < colors[target].length; i++) {
      document.documentElement.style.setProperty(variables[i],colors[target][i]);
    } 
  }
  
  return (
    <div className="dialogbox">
        <div className="body">
          <div className="message">
            <div className="dropline" onClick={handleClick}>Dark</div>
            <div className="dropline" onClick={handleClick}>Light</div>
            <div className="dropline" onClick={handleClick}>Blue</div>
            <div className="dropline" onClick={handleClick}>Green</div>
          </div>
        </div>
      </div>
  )
}

const Scene = () => {
  const scene = useRef();
  useFrame(() => {
    scene.current.rotation.y += 0.005;
    scene.current.rotation.x += 0.0005;
  });
  return (
    <group ref={scene}>
      <directionalLight intensity={0.5} />
        <ambientLight intensity={0.1} />
        <Suspense fallback={null}>
        <Model scale= {0.3} />
        </Suspense>
    </group>
  );
};

// APP
const App = () => {
  const [loading, setLoading] = useState(false); 
  const [cube, setCube] = useState("");

  useEffect(() => {
    setLoading(true);
    const interval = setInterval(() => {
      setCube(asciiFrame());
    }, 3);

    //do 3D stuff here

    setTimeout(() => {
      setLoading(false);   
     }, 0)

     // do 3d stuff here

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
       {loading ? (
        <div className="loading">
        <div className="container">
          <pre className="cube">{cube}</pre>
        </div>
      </div>
    ) : (
      <div>
      <div className="heading">
      <HeadingBlock></HeadingBlock>
      </div>
      <div id="root" style={{ height: "100vh" }}> 
      <Canvas camera={{ position: [0, 2, 5] }}>
        <OrbitControls />
        <PerspectiveCamera makeDefault position={[0, 2, 5]} />
        <directionalLight intensity={0.5} />
        <Scene />
    </Canvas>
    </div>
    <PlayerComponent></PlayerComponent>
    </div>
      )}
    </div>
  );
}


export default App