/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/dj.glb')
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions.ArmatureAction.play()
    actions.vinylAction.play()

  })
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Armature" position={[-0.01, -1.18, 0.03]} rotation={[0, 0, -0.26]} scale={[1, 2.09, 1.95]}>
          <primitive object={nodes.upperarml} />
          <primitive object={nodes.upperarmr} />
          <primitive object={nodes.upperlegr} />
          <primitive object={nodes.neutral_bone} />
          <skinnedMesh name="human" geometry={nodes.human.geometry} material={materials.person} skeleton={nodes.human.skeleton} />
        </group>
        <mesh name="player" geometry={nodes.player.geometry} material={materials.player} position={[2.01, -2.55, -0.06]} />
        <mesh name="vinyl" geometry={nodes.vinyl.geometry} material={materials.vinyl} position={[1.99, -1.45, -0.06]} scale={[0.81, 0.01, 0.81]} />
      </group>
    </group>
  )
}

useGLTF.preload('/dj.glb')
